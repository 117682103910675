import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP:control
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP:variant_1
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP:variant_2
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP:variant_3
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP:variant_4
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP:variant_5

// ?remove_preferred_experiment=NEW_BUYBOX_TV_SHOW_EXP

export const NewBuyBoxTVShowExpName = 'NEW_BUYBOX_TV_SHOW_EXP' as const;

export type NewBuyBoxTVShowExpVariants = ToVariants<typeof NewBuyBoxTVShowExpVariants>;

export const NewBuyBoxTVShowExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
	VARIANT_4: 'variant_4',
	VARIANT_5: 'variant_5',
} as const;

export default generate(NewBuyBoxTVShowExpName, NewBuyBoxTVShowExpVariants);
